import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from 'src/app/reducer/app.reducer';
import { environment } from 'src/environments/environment';
import { AuthModel } from '../models/auth.model';
import { ModelUser } from '../models/user.model';
import { AuthService } from '../services/auth.service';
import * as userActions from 'src/app/authentication/store/actions/user.actions';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  url = environment.url;

constructor(private httpClient: HttpClient, private router: Router, private store: Store< AppState >,
    private authService: AuthService ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      
      let ls = localStorage.getItem('_token');
      
      if(localStorage.getItem('_token') == 'success'){
        this.authService.getLoggedUser()
          .subscribe( (user:ModelUser) => {
            this.store.dispatch(  userActions.setUser({ user }) )
          }, (err) => {
            console.error(err);
          })
        return true;
      }else{
        this.router.navigate(['/authentication/login']);
        return false;
      }
  }


}
