export class AuthModel {

    isLogin: boolean;
    status: string;
    token_type: string;
    expires_in: number;
    access_token: string;
    refresh_token: string;

    constructor() {

        this.isLogin = false;
        this.status = null;
        this.token_type = null;
        this.expires_in = null;
        this.access_token = null;
        this.refresh_token = null;

    }

    hasExpired() {

        // return this.expires_in;
    }
}
