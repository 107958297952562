import { AuthService } from '../../services/auth.service';
import { tap, mergeMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AuthActions from '../actions/index';

@Injectable()
export class AuthEffects {

    constructor(private actions$: Actions, private authService: AuthService) { }

    loadUser$ = createEffect(
        () => this.actions$.pipe(
            ofType( AuthActions.login ),
            mergeMap(
                () => this.authService.getLoggedUser()
                    .pipe(
                        map( user =>  AuthActions.setUser( { user }) ),
                    )
            )
        )
    );

    unLoadUser$ = createEffect(
        () => this.actions$.pipe(
            ofType( AuthActions.logout),
            map( ()=> AuthActions.unSetUser() )
        )
    );
}
