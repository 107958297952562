import { createReducer, on } from '@ngrx/store';
import { ModelUser } from './../../models/user.model';
import { setUser, unSetUser } from '../actions/index';



export const initUserState = new ModelUser();

const _userReducer = createReducer(initUserState,
    on( setUser, 
        ( state, { user } ) => user ),
    on( unSetUser, state => new ModelUser()),
);

export function userReducer(state, action) {
    return _userReducer(state, action);
  }

