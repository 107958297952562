import { ModelUser } from './../../models/user.model';
import { authState } from '../reducers/auth.reducers';
import { createAction, props } from '@ngrx/store';
import { AuthModel } from '../../models/auth.model';
import { AppState } from 'src/app/reducer/app.reducer';


export const registrer = createAction('[AUTH] Register');
export const logout = createAction('[AUTH] Logout');

export const login = createAction(
    '[AUTH] Login',
    props<{ auth: AuthModel }>()
);


