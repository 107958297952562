import { ModelUser } from './../../models/user.model';
import { registrer, login, logout } from '../actions/auth.actions';
import { AuthModel } from '../../models/auth.model';
import { createReducer, on } from '@ngrx/store';


export const authState = new AuthModel();

const _authReducer = createReducer(authState,
  on(registrer, state => state),
  on(login, (state, {auth}) => auth),
  on(logout, state => new AuthModel()),
);

export function authReducer(state, action) {
  return _authReducer(state, action);
}


