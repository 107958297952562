import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AppState } from 'src/app/reducer/app.reducer';
import { ModelUser } from 'src/app/authentication/models/user.model';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};
  public isNotif = false;
  public notifCount = 0;
  public showSearch = false;
  url = environment.url;
  user: ModelUser = new ModelUser();
  
  constructor(private modalService: NgbModal, 
              private router: Router, 
              private authService: AuthService,
              private store:Store< AppState >,
              private httpClient: HttpClient) 
  {
    this.getNotifications();
    this.store.select( 'userState' )
      .subscribe( (user:ModelUser) => {
        this.user = user;
      })
  }

  // This is for Notifications
  // {
  //   btn: 'btn-danger',
  //   icon: 'ti-link',
  //   title: 'Luanch Admin',
  //   subject: 'Just see the my new admin!',
  //   time: '9:30 AM'
  // },
  // {
  //   btn: 'btn-success',
  //   icon: 'ti-calendar',
  //   title: 'Event today',
  //   subject: 'Just a reminder that you have event',
  //   time: '9:10 AM'
  // },
  // {
  //   btn: 'btn-info',
  //   icon: 'ti-settings',
  //   title: 'Settings',
  //   subject: 'You can customize this template as you want',
  //   time: '9:08 AM'
  // },
  // {
  //   btn: 'btn-primary',
  //   icon: 'ti-user',
  //   title: 'Pavan kumar',
  //   subject: 'Just see the my admin!',
  //   time: '9:00 AM'
  // }
  notifications: Object[] = [    
  ];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];


  getNotifications() {
    
    this.httpClient.get(`${this.url}notifications`)
      .subscribe( (response)=> {
        
        this.notifications = [];
        var info = response as [];
        this.notifCount = info.length;
        
        info.forEach( (notif) => {
          let tmp = {
            btn: 'btn-danger',
            icon: 'ti-link',
            title: 'Alerta',
            subject: notif['message'],
            time: notif['date'],
            id: notif['id']
          }

          this.notifications.push(tmp);
        })
        
      }, (err) => {
        console.error(err);
      })
  }

  checkNotif(notif){

    this.httpClient.put(`${this.url}notifications/${notif.id}`, new HttpParams())
      .subscribe( (response) => {

        this.getNotifications();
      })

    
  }
  logOut(){
    
    this.authService.logout();
  }

  ngAfterViewInit() {}
}
