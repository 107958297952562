import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { Store } from '@ngrx/store';
import { AppState } from '../reducer/app.reducer';
import { catchError, first, mergeMap } from 'rxjs/operators';
import { AuthModel } from '../authentication/models/auth.model';
import { isTokenExpired } from '../utils/utils';
import { AuthService } from '../authentication/services/auth.service';

@Injectable()
export class SecurityHeadersInterceptor implements HttpInterceptor {

constructor( private store: Store<AppState>, private authService: AuthService ){

}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const oldReq = httpRequest.clone()

    return this.store.select('authState').pipe(
        first(),
        mergeMap(( auth: AuthModel ) => {
            if( auth.isLogin && isTokenExpired( auth.expires_in )){
                const newReq = oldReq.clone({
                    headers: oldReq.headers
                        .set('Authorization', 'Bearer ' + auth.access_token )
                        .set('Accpet', 'application/json')
                }) ;
                return next.handle(newReq);
            }
            return next.handle(oldReq.clone({
                headers: oldReq.headers
                .set('Accpet', 'application/json')
            }));
        }),
        catchError((error) => {
            // Catch "401 Unauthorized" responses
            if ( error instanceof HttpErrorResponse && error.status === 401 )
            {
                // Sign out
                this.authService.logout();
            }

            return throwError(error);
        })
    );
      //any alteration in httpRequest can be done here
    return next.handle(httpRequest);
  }
}