import { ModelUser } from './../../models/user.model';
import { createAction, props } from '@ngrx/store';

export const unSetUser = createAction('[AUTH USER] Unset User');

export const setUser = createAction(
    '[AUTH USER] Set user action',
    props<{ user: ModelUser }>()
);


