import { Component, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Store } from '@ngrx/store';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar';
import { AppState } from './apps/email/app.state';
import { ModelUser } from './authentication/models/user.model';
import { AuthService } from './authentication/services/auth.service';
import * as userActions from 'src/app/authentication/store/actions/user.actions';
import * as authActions from 'src/app/authentication/store/actions/auth.actions';
import { AuthModel } from './authentication/models/auth.model';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
    private sub: any;

    constructor(private slimLoader: SlimLoadingBarService, 
                private router: Router, 
                private authService: AuthService,
                private store: Store<AppState>) {

        if(localStorage.getItem('_auth')){

            const auth = JSON.parse( localStorage.getItem( '_auth' ) ) as AuthModel;

            this.store.dispatch( authActions.login({ auth }) );
            
            this.authService.getLoggedUser()
                .subscribe( (user:ModelUser) => {
                    this.store.dispatch(  userActions.setUser({ user }) )
                }, (err) => {
                    this.router.navigate(['/authentication/login']);
                })
        }
        // Listen the navigation events to start or complete the slim bar loading
        this.sub = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.slimLoader.start();
            } else if (event instanceof NavigationEnd ||
                event instanceof NavigationCancel ||
                event instanceof NavigationError) {
                this.slimLoader.complete();
            }
        }, (error: any) => {
            this.slimLoader.complete();
        });
    }

    ngOnDestroy(): any {
        this.sub.unsubscribe();
    }
}
