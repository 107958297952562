import { ModelDevice } from "src/app/models/device.model";

export class ModelUser {

    id: number;
    email: string;
    name: string;
    phone: string;
    lastName: string;
    country: string;
    region: string;
    city: string;
    address: string;
    rol: string = '';
    current_company: string;
    password: string;
    devices: ModelDevice[] = [];
    isAsc: boolean = false;

    constructor(){}

}
