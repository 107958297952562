import { ModelUser } from './../models/user.model';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState } from 'src/app/reducer/app.reducer';
import { Store } from '@ngrx/store';
import * as authActions from 'src/app/authentication/store/actions/auth.actions';
import { map, tap } from 'rxjs/operators';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar/src/slim-loading-bar.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private url = environment.url;
  accesToken = '';
  private securityHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('access_token')}`
  });

  constructor( private httpClient: HttpClient,
               private store: Store<AppState>, 
               private router: Router,
               private slimLoader: SlimLoadingBarService) {
                 
    this.store.subscribe( (state) => {
      this.accesToken = `Bearer ${state.authState.access_token}`;

      });
  }

  logIn(username: string, password: string) {

    const endPoint = `${this.url}oauth/token`;
    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('client_id', environment.client_id)
      .set('client_secret', environment.client_secret)
      .set('username', username)
      .set('password', password);

    return this.httpClient.post(endPoint, body);

  }

  refreshToken(refreshToken: string) {
    const endPoint = `${this.url}oauth/token`;
    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('refresh_token', refreshToken)
      .set('client_id', environment.client_id)
      .set('client_secret', environment.client_secret);

    return this.httpClient.post(endPoint, body);
  }

  getLoggedUser() {

    this.securityHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.accesToken
    });

    const url = `${this.url}users/getLoggedUser`;

    return this.httpClient.get<ModelUser>(url, { headers: this.securityHeaders })
    .pipe(
      map( resp => resp as ModelUser)
    );
  }

  logout() {

    localStorage.clear();
    this.store.dispatch( authActions.logout() );
    this.router.navigate(['authentication/login']);
  }

  registerUsder( body: HttpParams ) {

    return this.httpClient.post( `${this.url}users`, body );
  }

  validateUser( body: HttpParams ) {

    return this.httpClient.post( `${this.url}users/validateUser`, body);
  }

}
