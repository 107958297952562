import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard/dashboard1',
    title: 'Tablero de Control',
    icon: 'icon-Car-Wheel',
    class: '',
    ddclass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/devices/devices',
    title: 'Dispositivos',
    icon: 'icon-Device-SyncwithCloud',
    class: '',
    ddclass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/devices/variables',
    title: 'Variables',
    icon: 'icon-Device-SyncwithCloud',
    class: '',
    ddclass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/reportes/reportes',
    title: 'Reportes',
    icon: 'icon-File-Graph',
    class: '',
    ddclass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/users/users',
    title: 'Usuarios',
    icon: 'icon-User',
    class: '',
    ddclass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Seguridad',
    icon: 'icon-Computer-Secure',
    class: '',
    ddclass: '',
    extralink: false,
    submenu: []
  },
  // {
  //   path: '',
  //   title: 'Apps',
  //   icon: 'icon-Box-withFolders',
  //   class: '',
  //   ddclass: '',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/apps/email',
  //       title: 'Email',
  //       icon: 'icon-Mailbox-Empty',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/apps/ticketlist',
  //       title: 'Ticket List',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/apps/ticketdetails',
  //       title: 'Ticket Details',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/apps/chat',
  //       title: 'Chat App',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/apps/fullcalendar',
  //       title: 'Calendar',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/apps/taskboard',
  //       title: '',
  //       icon: 'mdi mdi-bulletin-board',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'UI',
  //   icon: 'icon-Duplicate-Window',
  //   class: 'has-arrow',
  //   ddclass: 'mega-dropdown',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/component/accordion',
  //       title: 'Accordion',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/alert',
  //       title: 'Alert',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/carousel',
  //       title: 'Carousel',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/dropdown',
  //       title: 'Dropdown',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/modal',
  //       title: 'Modal',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/pagination',
  //       title: 'Pagination',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/poptool',
  //       title: 'Popover & Tooltip',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/progressbar',
  //       title: 'Progressbar',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/rating',
  //       title: 'Ratings',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/tabs',
  //       title: 'Tabs',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/timepicker',
  //       title: 'Timepicker',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/buttons',
  //       title: 'Button',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/cards/basiccards',
  //       title: 'Basic Cards',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/cards/customcards',
  //       title: 'Custom Cards',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/cards/weathercards',
  //       title: 'Weather Cards',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/extra-component/toastr',
  //       title: 'Toastr',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/extra-component/upload',
  //       title: 'File Upload',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/extra-component/editor',
  //       title: 'Editor',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/extra-component/dragndrop',
  //       title: 'Drag n Drop',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/widgets/apps',
  //       title: 'Widget Apps',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/widgets/data',
  //       title: 'Widget Data',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Forms',
  //   icon: 'icon-File-HorizontalText',
  //   class: 'has-arrow',
  //   ddclass: 'two-column',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/forms/forminputs',
  //       title: 'Form Inputs',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/inputgroups',
  //       title: 'Input Groups',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/inputgrid',
  //       title: 'Input Grid',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/checkboxandradio',
  //       title: 'Checkbox & Radio',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/formbasic',
  //       title: 'Basic Forms',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/formhorizontal',
  //       title: 'Horizontal Forms',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/formactions',
  //       title: 'Form Actions',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/formrowseparator',
  //       title: 'Row Separator',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/formstripedrows',
  //       title: 'Striped Rows',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/formdetail',
  //       title: 'Detail Forms',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/formbasic',
  //       title: 'Basic Forms',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/formhorizontal',
  //       title: 'Horizontal Forms',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/formactions',
  //       title: 'Form Actions',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/formrowseparator',
  //       title: 'Row Separator',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/formstripedrows',
  //       title: 'Striped Rows',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/formdetail',
  //       title: 'Detail Forms',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/formvalidation',
  //       title: 'Form Validation',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/typehead',
  //       title: 'Form Typehead',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/datepicker',
  //       title: 'Datepicker',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/component/language-datepicker',
  //       title: 'Language Datepicker',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/multiselect',
  //       title: 'Multiselect',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Tables',
  //   icon: 'icon-Navigation-LeftWindow',
  //   class: 'has-arrow',
  //   ddclass: '',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '',
  //       title: 'Bootstrap Tables',
  //       icon: 'mdi mdi-border-none',
  //       class: 'has-arrow',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: [
  //         {
  //           path: '/tables/basictables',
  //           title: 'Basic Tables',
  //           icon: 'mdi mdi-border-all',
  //           class: '',
  //           ddclass: '',
  //           extralink: false,
  //           submenu: []
  //         },
  //         {
  //           path: '/tables/darktables',
  //           title: 'Dark Basic Tables',
  //           icon: 'mdi mdi-border-all',
  //           class: '',
  //           ddclass: '',
  //           extralink: false,
  //           submenu: []
  //         },
  //         {
  //           path: '/tables/colortables',
  //           title: 'Colored Tables',
  //           icon: 'mdi mdi-border-all',
  //           class: '',
  //           ddclass: '',
  //           extralink: false,
  //           submenu: []
  //         },
  //         {
  //           path: '/tables/tablesizing',
  //           title: 'Table Sizing',
  //           icon: 'mdi mdi-border-all',
  //           class: '',
  //           ddclass: '',
  //           extralink: false,
  //           submenu: []
  //         }
  //       ]
  //     },
  //     {
  //       path: '/tables/smarttable',
  //       title: 'Smart Tables',
  //       icon: 'mdi mdi-border-left',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/tables/datatable',
  //       title: 'Data Tables',
  //       icon: 'mdi mdi-border-vertical',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Charts',
  //   icon: 'icon-Neutron',
  //   class: 'has-arrow',
  //   ddclass: '',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/charts/chartjs',
  //       title: 'Chart Js',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/charts/chartistjs',
  //       title: 'Chartist Js',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/charts/ngxchart',
  //       title: 'Ngx Charts',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Pages',
  //   icon: 'icon-File-Hide',
  //   class: 'has-arrow',
  //   ddclass: 'mega-dropdown',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/ecom/products',
  //       title: 'Products',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ecom/cart',
  //       title: 'Cart',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ecom/edit',
  //       title: 'Edit Products',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ecom/details',
  //       title: 'Product Details',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ecom/orders',
  //       title: 'Orders',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ecom/checkout',
  //       title: 'Checkout',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/maps/google',
  //       title: 'Google Maps',
  //       icon: 'icon-Location-2',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/authentication/login',
  //       title: 'Login',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/authentication/login2',
  //       title: 'Login 2',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/authentication/signup',
  //       title: 'Register',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/authentication/signup2',
  //       title: 'Register 2',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/authentication/404',
  //       title: '404',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/authentication/lock',
  //       title: 'Lockscreen',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/sample-pages/profile',
  //       title: 'Profile',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/sample-pages/pricing',
  //       title: 'Pricing',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/sample-pages/invoice',
  //       title: 'Invoice',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/sample-pages/helperclasses',
  //       title: 'Helper Classes',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/starter',
  //       title: 'Starter Page',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/timeline/left',
  //       title: 'Left Timeline',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/timeline/right',
  //       title: 'Right Timeline',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/timeline/center',
  //       title: 'Center Timeline',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/icons/fontawesome',
  //       title: 'Fontawesome',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/icons/simpleline',
  //       title: 'Simple Line Icons',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/icons/material',
  //       title: 'Material Icons',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/icons/iconmind',
  //       title: 'Iconmind Icons',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'DD',
  //   icon: 'icon-Increase-Inedit',
  //   class: 'has-arrow',
  //   ddclass: '',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '',
  //       title: 'Second Level',
  //       icon: '',
  //       class: '',
  //       ddclass: '',
  //       extralink: true,
  //       submenu: []
  //     },
  //     {
  //       path: '',
  //       title: 'Second Child',
  //       icon: '',
  //       class: 'has-arrow',
  //       ddclass: '',
  //       extralink: false,
  //       submenu: [
  //         {
  //           path: '',
  //           title: 'Third 1.1',
  //           icon: '',
  //           class: '',
  //           ddclass: '',
  //           extralink: false,
  //           submenu: []
  //         },
  //         {
  //           path: '',
  //           title: 'Third 1.2',
  //           icon: '',
  //           class: '',
  //           ddclass: '',
  //           extralink: false,
  //           submenu: []
  //         }
  //       ]
  //     }
  //   ]
  // }
];
