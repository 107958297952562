import { HttpErrorResponse } from "@angular/common/http";
import { FormGroup } from "@angular/forms";

export const isTokenExpired = (expirationDate: number, offsetSeconds?: number): boolean => {
    // Return if there is no token
    if ( !expirationDate ){ return true;}

    // Get the expiration date
    const date = new Date(0);
    date.setUTCSeconds(expirationDate);

    offsetSeconds = offsetSeconds || 0;

    if ( date === null ){ return true; }

    // Check if the token is expired
    return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
}

export const httpErrorToString = ( err: HttpErrorResponse ): string => {
    
    let errorMessage = '';
    for (let key in err.error.errors) {
        let value = err.error.errors[key];

        errorMessage += ' '+value[0]; 
    }        
    return errorMessage;
}


export function ConfirmedValidator(controlName: string, matchingControlName: string){
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ confirmedValidator: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}