import { ModelUser } from './../authentication/models/user.model';
import { AuthModel } from '../authentication/models/auth.model';
import *  as authReducers from '../authentication/store/reducers/index';
import { ActionReducerMap } from '@ngrx/store';


export interface AppState {
    authState: AuthModel;
    userState: ModelUser;
}

export const appReducer: ActionReducerMap<AppState> = {

    authState: authReducers.authReducer, 
    userState: authReducers.userReducer

};
